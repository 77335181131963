<template>
  <div>
    <div class="py-8 px-0" v-if="!loading">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Dashboard</h1>
        <div class="w-3/6 flex gap-3">
          <input
            type="date"
            v-model="filter.start_date"
            onfocus="(this.type='date')"
            onblur="(this.type='text')"
            placeholder="Filter start date"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          />
          <input
            type="date"
            v-model="filter.end_date"
            onfocus="(this.type='date')"
            onblur="(this.type='text')"
            placeholder="Filter end date"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          />
          <input
            disabled
            :value="user.branch.branch_name"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-white py-2 px-3 rounded-md w-1/3"
          />
        </div>
      </div>
      <div class="my-5 flex gap-5">
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-blue-300 bg-opacity-30 p-3 rounded-full">
            <heart-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.staff_count || 0 }}
            </h3>
            <p>No of Users</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-yellow-300 bg-opacity-30 p-3 rounded-full">
            <game-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.corpse_count || 0 }}
            </h3>
            <p>Number of Corpse</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-red-300 bg-opacity-30 p-3 rounded-full">
            <bag-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.enquiry_count || 0 }}
            </h3>
            <p>Number of Enquiries Logged</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4">
          <div class="bg-primary bg-opacity-30 p-3 rounded-full">
            <work-icon class="w-5 h-auto" />
          </div>
          <div class="">
            <h3 class="text-2xl font-bold text-gray-700">
              {{ dashboard_items.corpse_release_count || 0 }}
            </h3>
            <p>Number of bodies Released</p>
          </div>
        </div>
      </div>
      <div class="flex gap-4 items-start">
        <div
          class="w-2/3 bg-white shadow-md rounded-xl p-5"
          v-if="corpses.length !== 0"
        >
          <h2 class="font-bold text-2xl mb-3">Recent Admission</h2>
          <addmission-table
            class="mt-1"
            :allCorpse="corpses.slice(0, 3)"
            :perPage="corpsePerPage"
            :currentPage="currentPage"
            :total="totalCorpse"
            :actions="false"
            @fetchPage="
              $toast.error(
                'Action can only be continued by the front desk officer'
              )
            "
            @hide="
              $toast.error(
                'Action can only be continued by the front desk officer'
              )
            "
            @view="
              $toast.error(
                'Action can only be continued by the front desk officer'
              )
            "
            @continue="
              $toast.error(
                'Action can only be continued by the front desk officer'
              )
            "
          />
        </div>
        <div class="w-1/3 bg-white shadow-md rounded-xl p-5">
          <h2 class="font-bold text-2xl mb-3">Calender</h2>
          <vc-calendar
            is-expanded
            title-position="right"
            @update:to-page="dateChange"
          />
        </div>
      </div>
    </div>
    <div class="h-full w-full flex items-center justify-center" v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import { fetchBranches } from "@/services/branch";
import { executive, removalLogs } from "@/services/dashboard";
import { fetchcorpse } from "@/services/addmission";

import "@/plugins/apex-charts";

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
      role: [],
      filter: {
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        branch: "",
      },
      dashboard_items: [],
      corpses: [],
      chartOptions: {
        chart: {
          id: "vuechart-example",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
      donut: {
        options: {
          legend: {
            position: "bottom",
          },
          chart: {
            id: "donut",
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
        },
        series: [44, 55, 12, 34],
      },
    };
  },
  components: {
    HeartIcon: () => import("@/assets/icons/HeartIcon"),
    GameIcon: () => import("@/assets/icons/GameIcon"),
    BagIcon: () => import("@/assets/icons/BagIcon"),
    WorkIcon: () => import("@/assets/icons/WorkIcon"),
    AddmissionTable: () =>
      import("@/components/Addmission/AddmissionTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
    user() {
      return this.$store.state.userDetail;
    },
  },
  methods: {
    dateChange(e) {
      this.fetchRemovalLogs(e.month);
    },
    async fetchRemovalLogs(month = 1) {
      try {
        const response = await removalLogs(month, this.user.branch_id);
        console.log(response);
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
    async fetchAllBranches(page = 1) {
      try {
        const response = await fetchBranches(page);
        this.$store.commit("SET_BRANCHES", response.data.branch.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDashboard() {
      this.loading = true
      try {
        this.filter.branch = this.user.branch.id;
        const response = await executive(this.filter);
        this.dashboard_items = response.data.data;
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loading = false;
    },
    async fetchAllCorpse(page = 1) {
      this.sideModal = false;
      this.overlay = false;
      this.editCorpseDetail = {};
      this.push = 1;
      try {
        const response = await fetchcorpse(page);
        this.corpses = response.data.corpse.data;
        this.corpsePerPage = response.data.corpse.per_page;
        this.totalCorpse = response.data.corpse.total;
        this.currentPage = response.data.corpse.current_page;
      } catch (error) {
        this.$toast.error("An error occured, please try again");
        console.log(error);
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.fetchDashboard();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAllBranches();
    this.fetchDashboard();
    this.fetchAllCorpse();
    this.fetchRemovalLogs();
  },
};
</script>
